import {
  CloseOutlined,
  CheckOutlined,
  HourglassOutlined,
  SyncOutlined,
  UsergroupAddOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import {
  Avatar,
  Anchor,
  Button,
  Card,
  Col,
  ConfigProvider as ConfigProvider5,
  DatePicker,
  Divider,
  Drawer,
  Empty,
  Flex,
  FloatButton,
  Form,
  Layout,
  List,
  Popconfirm,
  Row,
  Segmented,
  Select,
  Spin,
  Statistic,
  Skeleton,
  Tag,
  Typography
} from 'antd-v5';
import dayjs from 'dayjs';
import React from 'react';
import Auth from '../../Auth';
import { MobileNav } from './Nav';
const { Content, Sider } = Layout;
const { Text, Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;


const App = () => {
  const [loading, setLoading] = React.useState(false);
  const [anchorDate, setAnchorDate] = React.useState(dayjs().startOf("day"));
  const [timeRequests, setTimeRequests] = React.useState([]);
  const [timeRecords, setTimeRecords] = React.useState([]);
  const [openSelector, setOpenSelector] = React.useState(false);
  const [selector] = Form.useForm();
  const [mode, setMode] = React.useState("day");

  var weekItems = [
    {
      key: "Sunday",
      href: '#/app/crew-builder#sunday',
      title: "Sunday",
    },
    {
      key: 'Monday',
      href: '#/app/crew-builder#monday',
      title: 'Monday',
    },
    {
      key: 'Tuesday',
      href: '#/app/crew-builder#tuesday',
      title: 'Tuesday',
    },
    {
      key: "Wednesday",
      href: "#/app/crew-builder#wednesday",
      title: "Wednesday",
    },
    {
      key: "Thursday",
      href: "#/app/crew-builder#thursday",
      title: "Thursday",
    },
    {
      key: "Friday",
      href: "#/app/crew-builder#friday",
      title: "Friday",
    },
    {
      key: "Saturday",
      href: "#/app/crew-builder#saturday",
      title: "Saturday",
    }
  ]

  const computeDaysOfWeek = () => {
    const weekDates = [];
    const startOfWeek = dayjs(anchorDate).startOf("week");
    for (var i = 0; i < 7; i++) {
      weekDates.push([startOfWeek.add(i, "day").startOf("day"), startOfWeek.add(i, "day").endOf("day")]);
    }

    return weekDates;
  }

  const fetchTimeRequests = () => {
    setLoading(true);
    const requestAt = dayjs(anchorDate).startOf("week").toISOString();
    const requestEndsAt = dayjs(anchorDate).endOf("week").toISOString();

    fetch(`/api/users/${Auth.getCurrentUser()?.id}/production-requests?startsAt=${requestAt}&endsAt=${requestEndsAt}&includeSubmitted=true`)
      .then((response) => response.json())
      .then((response) => {
        const filtered = response.filter(p => dayjs(p.requestAt) >= dayjs(anchorDate).startOf("week") && dayjs(p.requestAt) <= dayjs(anchorDate).endOf("week"))
        setTimeRequests(filtered);
        setLoading(false);
      })
  }


  const onWeekSelect = (value) => setAnchorDate(value);
  const openSelectorDrawer = () => setOpenSelector(true);
  const closeSelectorDrawer = () => setOpenSelector(false);

  const onAnchorDateSelect = (value) => {
    setAnchorDate(value);
  }

  const onPreviousWeekSelect = () => {
    const prev = dayjs(anchorDate).subtract(1, mode);
    setAnchorDate(prev);
  }

  const onNextWeekSelect = () => {
    const next = dayjs(anchorDate).add(1, mode);
    setAnchorDate(next);
  }

  const onModeChange = (value) => {
    setMode(value);
  }

  const onSelectorFormSubmit = async () => {
    fetchTimeRequests();
    closeSelectorDrawer();
  }

  React.useEffect(() => {
    fetchTimeRequests()
  }, [anchorDate]);

  return (
    <ConfigProvider5 prefixCls="ant5">

      <Spin spinning={loading} fullscreen={true} />

      <Content style={{
        marginTop: 16,
        maxWidth: 800,
        minWidth: "90vw"
      }}>

        {(anchorDate) &&
          <div>
            <Row gutter={16}>
              <Col span={24}>
                <Text type="secondary">Schedule</Text>
                <Title level={4} style={{ marginTop: 8 }}>My Schedule</Title>
              </Col>
            </Row>
            <Segmented
              block
              size="large"
              value={mode}
              options={[
                { label: 'Daily', value: 'day', disabled: false },
                { label: 'Weekly', value: 'week', disabled: false },
              ]}
              onChange={onModeChange}
            />
            {mode == "day" &&
              <div style={{ marginTop: 24 }}>
                <Segmented
                  block
                  defaultValue={anchorDate.toISOString()}
                  value={anchorDate.toISOString()}
                  onChange={value => {
                    setAnchorDate(dayjs(value))
                  }}
                  options={
                    computeDaysOfWeek()?.map(d => {
                      const matchingTimeRequest = timeRequests?.find(p => dayjs(p.requestAt).format("YYYY-MM-DD") == d[0].format("YYYY-MM-DD"));

                      let color = "#bfbfbf"
                      const pendingColor = "#f56a00"
                      const clearedColor = "#87d068"

                      if (matchingTimeRequest && matchingTimeRequest.productionId) color = clearedColor
                      if (matchingTimeRequest && !matchingTimeRequest.productionId) color = pendingColor

                      return {
                        label: (
                          <div style={{ margin: "6px 0px" }}>
                            <Avatar style={{ backgroundColor: color }}>{d[0].format("dd")}</Avatar>
                            <div>
                              {d[0].format("MM-DD")}
                            </div>

                          </div>
                        ),
                        value: d[0].toISOString(),
                      }
                    })
                  }
                />
              </div>
            }
          </div>
        }

        <Layout
          className="site-layout-background"
          style={{
            borderRadius: 8,
            marginTop: 16
          }}
        >

          <Content
            style={{
              padding: '12px 12px',
              minHeight: "100vh"
            }}
          >
            {
              (anchorDate) &&
              <div>
                <Flex justify="space-between" align="flex-end" style={{ marginBottom: 24 }}>
                  <Button type="primary" shape="round" onClick={onPreviousWeekSelect} icon={<ArrowLeftOutlined />} style={{ display: 'flex' }}>
                    Prev {mode.charAt(0).toUpperCase() + mode.slice(1)}
                  </Button>
                  <Button type="primary" shape="round" onClick={onNextWeekSelect} icon={<ArrowRightOutlined />} iconPosition="end">
                    Next {mode.charAt(0).toUpperCase() + mode.slice(1)}
                  </Button>
                </Flex>
                <div style={{ textAlign: "center", marginBottom: 48 }}>
                  <Text type="secondary">{mode.charAt(0).toUpperCase() + mode.slice(1)}</Text>
                  <Title level={4} style={{ marginTop: 8 }}>{
                    anchorDate && mode == "week"
                      ? `${dayjs(anchorDate).startOf(mode).format("MMMM D")} to ${dayjs(anchorDate).endOf(mode).format("MMMM D")}`
                      : dayjs(anchorDate).startOf(mode).format("MMMM D")}
                  </Title>
                </div>
              </div>
            }
            {!loading && timeRequests.length >= 0 && weekItems.map(dayOfWeek => {
              if (mode == "day" && anchorDate && dayOfWeek.title != anchorDate.format("dddd")) return;
              return (
                <>
                  <Divider>
                    {dayOfWeek.title}
                  </Divider>

                  <List
                    loading={loading}
                    itemLayout="horizontal"
                    dataSource={timeRequests.filter(x => dayjs(x.requestAt).format("dddd") == dayOfWeek.title)}
                    renderItem={(request) => {
                      const scheduleText = `${dayjs(request.requestAt).format("hh:mm a")} ${request.requestEndsAt ? "to" : ""} ${request.requestEndsAt ? dayjs(request.requestEndsAt).format("HH:mm a") : ""}`
                      return (
                        <List.Item>
                          <Skeleton avatar title={false} loading={loading} active>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  shape="square"
                                  icon={request.productionId ? <CheckOutlined /> : <HourglassOutlined />}
                                  style={request.productionId ? {
                                    backgroundColor: "#87d068"
                                  } : {}}
                                  onClick={() => window.location.href = `#/app/time-record-form?productionRequestId=${request.id}&jobId=${request.job.id}&userId=${request.user.id}&producedAt=${encodeURIComponent(request.requestAt)}&producedEndsAt=${encodeURIComponent(request.requestEndsAt)}`}
                                />
                              }
                              title={`${request.job.name} - ${request.job.description}`}
                              description={
                                scheduleText
                              }
                            />
                          </Skeleton>
                        </List.Item>
                      )
                    }}
                  />

                </>
              )
            })}

          </Content>

        </Layout>

        <Drawer
          title="Job Schedule"
          placement="right"
          onClose={closeSelectorDrawer}
          open={openSelector}
        >
          <Form
            form={selector}
            onFinish={onSelectorFormSubmit}
            layout="vertical"
          >
            <Form.Item
              name="week"
              label="Week"
              required
            >
              <DatePicker
                style={{
                  width: "100%"
                }}
                onChange={onWeekSelect}
                picker="week" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">View</Button>
            </Form.Item>
          </Form>
        </Drawer>

        <FloatButton.Group size="large" shape="square" style={{ right: 24, bottom: 120 }}>
          <FloatButton icon={<SyncOutlined />} onClick={openSelectorDrawer} />
          <FloatButton.BackTop visibilityHeight={300} />
        </FloatButton.Group>
      </Content >
      {/* <MobileNav /> */}
    </ConfigProvider5 >
  )
}

export default App;